import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import ampPic from "../../../static/images/distant_amp/fleshweaver.webp"
import ampvid from "../../../static/images/distant_amp/bg_desktop.mp4"
import ampvidMobile from "../../../static/images/distant_amp/bg_desktop.mp4"
import thumbnail from "../../../static/images/distant_amp/bg_image.jpg"
import thumbnailMobile from "../../../static/images/distant_amp/bg_image.jpg"

const Image = () => {
  return <img fetchpriority="high" alt="Tone Suite Distant" src={ampPic} width="100%" style={{ maxWidth: "800px" }}></img>
}

const DistantFrontpageTile = () => {

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const [animationDone, setAnimationDone] = useState(true);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < window.innerHeight
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  return (
    <Box
      sx={{
        backgroundSize: "cover",
        position: "relative",
        m: 0,
        flex: 100,
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        display: { xs: "block", md: "flex" },
        flexDirection: "column",
        flexGrow: 10,
        height: "100%",
        width: " 100%",
        overflow: "hidden"
      }}
    >
      <Box sx={{
        position: "absolute",
        top: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
        overflow: "hidden"
      }}>
        <video style={{
          minWidth: "100%",
          minHeight: "100%",
          height: "auto",
          width: "auto",
          position: "absolute",
          zIndex: -2,
          objectFit: "cover",
          backgroundColor: "black",
          pointerEvents: "none",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)"
        }}
          poster={state.mobileView ? thumbnailMobile : thumbnail}
          preload="metadata"
          muted defaultMuted autoPlay playsInline loop oncontextmenu="return false;" onEnded={() => setAnimationDone(true)}>
          <source src={state.mobileView ? ampvidMobile : ampvid} type="video/mp4"></source>
        </video>
        <div
          style={{
            zIndex: -1,
            position: "absolute",
            pointerEvents: "none",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.0) 0%, #000 95%)",
          }}
        ></div>
      </Box>
      <Box sx={{ p: 2 }}>
        <Image />
        <Typography
          variant="h3"
          fontSize={30}
          sx={{ fontSize: 30, }}
        >
          Tone Suite - Distant
        </Typography>
        <Typography
          // fontFamily={"pirulen"}
          style={{
            fontSize: state.mobileView ? "15px" : "20px",
            // fontWeight: 500,
          }}
        >
          <i>Energy. Intensity. Brutality.</i>
        </Typography>
        <Button
          sx={{
            mt: 4,
            background: "white",
            color: "black", width: { xs: "75%", md: "150px" }
          }}
          variant="outlined"
          onClick={() => {
            window.location.href = "/product/distant-amplifier-suite"
          }}
        >
          Learn More
        </Button>
      </Box>
    </Box>
  );
}

export default DistantFrontpageTile;