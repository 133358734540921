import { Grid, Typography, Box } from "@mui/material";


import cabimage from "../../../static/images/distant_amp/cab_side.webp"

const CabinetBanner = () => {
  const textColor = "#000"
  return <>
    <Box sx={{ p: 5, background: "radial-gradient(circle, #FFC63F 0%, #B78E2C 100%)", justifyContent: "center", alignItems: "center", color: textColor }} >
      <Grid container justifyContent={"center"} sx={{}}>
        <Grid item xs={12} md={10}>
          <img src={cabimage} width="100%" alt="Cabinets" />
        </Grid>

      </Grid>
      <Grid container justifyContent={"center"} sx={{}}>
        <Grid item xs={12} md={6} lg={6} sx={{}}>
          <Typography sx={{ textAlign: "center", mb: 3, }} variant="h3">Cabinets and IRs</Typography>
          <Typography sx={{ textAlign: "center", mb: 3 }} variant="h6">3 Cabinets with 9 virtual microphones each are good for almost 3000 impulse responses. Prefer your own IR files? No problem, load them alongside the virtual cabinets and create your own unique blends. </Typography>
          <Typography sx={{ textAlign: "center", mb: 3 }} variant="h6"></Typography>
        </Grid>
      </Grid>

    </Box >
  </ >
}

export default CabinetBanner;
