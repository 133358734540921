import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import ampPic from "../../../static/images/revf/rev_f_software_small.webp"
import ampvid from "../../../static/images/revf/rev_f_video_desktop.mp4"
import ampvidMobile from "../../../static/images/revf/rev_f_video_mobile.mp4"

const Image = () => {
  return <img fetchpriority="high" alt="Amp Suite Rev F" src={ampPic} width="100%" style={{ maxWidth: "400px" }}></img>
}

const RevFFrontpageTile = () => {

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const [animationDone, setAnimationDone] = useState(true);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < window.innerHeight
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  return (
    <Box
      sx={{
        backgroundSize: "cover",
        position: "relative",
        p: 0,
        m: 0,
        flex: 100,
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        display: { xs: "block", md: "flex" },
        flexDirection: "column",
        flexGrow: 10,
        height: "100%",
        p: 2
      }}
    >
      <video style={{
        width: "100%",
        height: "100%",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        position: "absolute",
        zIndex: -1,
        objectFit: "cover",
        backgroundColor: "black",
        pointerEvents: "none"
      }} muted defaultMuted autoPlay playsInline oncontextmenu="return false;" preload="auto" onEnded={() => setAnimationDone(true)}>
        <source loading="lazy" fetchpriority="low" src={state.mobileView ? ampvidMobile : ampvid} type="video/mp4"></source>
      </video>

      <Image />
      <Typography
        variant="h3"
        fontSize={30}
        sx={{ fontSize: 30, }}
      >
        Amp Suite - Rev F
      </Typography>
      <Typography
        // fontFamily={"pirulen"}
        style={{
          fontSize: state.mobileView ? "15px" : "20px",
          // fontWeight: 500,
        }}
      >
        Classic tones from the 90's
      </Typography>
      <Button
        sx={{
          mt: 4,
          background: "white",
          color: "black", width: { xs: "75%", md: "150px" }
        }}
        variant="outlined"
        onClick={() => {
          window.location.href = "/product/rev-f-amplifier-suite"
        }}
      >
        Learn More
      </Button>
    </Box>
  );
}

export default RevFFrontpageTile;