import { Grid, Typography, Box } from "@mui/material";

import eqImage from "../../../static/images/distant_amp/eq_view.webp"

const textColor = "#000"

const EQBanner = () => {
  return <>
    <Box sx={{ p: 5, background: "radial-gradient(circle, #eee 0%,#aaa 100%)", justifyContent: "center", alignItems: "center", color: textColor }} >
      <Grid container justifyContent={"center"} sx={{}}>
        <Grid item xs={12} md={10}>
          <img src={eqImage} width="100%" alt="Cabinets" />
        </Grid>

      </Grid>
      <Grid container justifyContent={"center"} sx={{}}>
        <Grid item xs={12} md={6} lg={6} sx={{}}>
          <Typography sx={{ textAlign: "center", mb: 3, }} variant="h3">7-Band Equalizer</Typography>
          <Typography sx={{ textAlign: "center", mb: 3 }} variant="h6">Fine tune your sound using the built in 7-band EQ together with the low and high pass filtering. Not satisfied yet? Try the secret "Sauce" button and be amazed. Your guitar will instantly fit in the mix.</Typography>
          <Typography sx={{ textAlign: "center", mb: 3 }} variant="h6"></Typography>
        </Grid>
      </Grid>

    </Box >
  </ >
}

export default EQBanner;
