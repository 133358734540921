import axios from "axios";

export const startCheckout = async (products: any, email: string, cancelURL: string) => {
    const data = {
        products: products,
        error_url: cancelURL,
        customer_email: email
    }

    return await axios.post(`/api/payment/start`, data)
}

export const getPricing = async (checkoutID: any) => {
    return axios.get(`/api/payment/pricing/${checkoutID}`)
}