import { Grid, Typography, Box } from "@mui/material";

import util1 from "../../../static/images/distant_amp/Util.webp"
import tuner from "../../../static/images/distant_amp/tuner.jpg"

const textColor = "#000"

const PluginUtilities = () => {
  return <>
    <Box sx={{ p: 5, background: "radial-gradient(circle, #eee 0%,#aaa 100%)", justifyContent: "center", alignItems: "center", color: textColor }} >
      <Grid container justifyContent={"center"} sx={{ alignItems: "center" }}>

        <Grid item xs={12} md={6} sx={{ p: { xs: 0, lg: 6 }, justifyContent: "center" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <img src={util1} width="100%" alt="Cabinets" style={{ borderRadius: "10px" }} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={4} sx={{ mt: { xs: 4, md: 0 } }}>
          <Typography variant="h3"> Utilities </Typography>
          <br />
          <Typography variant="h6" sx={{ textAlign: "left" }}><b>Gate:</b> Tighten your sound with the built in gate. </Typography>
          <br />
          <Typography variant="h6" sx={{ textAlign: "left" }}><b>Drop:</b> Transpose your guitar down by up to 12 semitones for those ultra low notes.</Typography>
          <br />
          <Typography variant="h6" sx={{ textAlign: "left" }}><b>Comp:</b> Control the low end with this proprietary low frequency compressor. It only clamps down on the palm mutes, without sucking too much tone out of the guitar.</Typography>
          <br />
          <Typography variant="h6" sx={{ textAlign: "left" }}><b>Smooth:</b> Get rid of that icy pick sound by turning up the smooth knob.</Typography>

        </Grid>
      </Grid>
      <Grid container justifyContent={"center"} sx={{}}>

        <Grid item xs={12} md={6} lg={4}>
          <Typography variant="h3"> Tuner </Typography>
          <Typography variant="h6" sx={{ textAlign: "left" }}> Quickly tune up your instrument. The built in tuner allows you to always stay in tune and with the built in mute button, you won't have to annoy anyone in the process ;)  </Typography>
        </Grid>
        <Grid item xs={12} md={6} sx={{ mt: { xs: 3, md: 0 }, p: { xs: 0, lg: 6 }, justifyContent: "center" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <img src={tuner} width="70%" alt="Cabinets" style={{ borderRadius: "10px" }} />
          </Box>
        </Grid>
      </Grid>

    </Box >
  </ >
}

export default PluginUtilities;
