import { Grid, Typography, Box, Card, CardContent } from "@mui/material";

import ecocideBand from "../../../static/images/distant_amp/distant_intro.jpg"

const TEXT_COLOR = "#fff";

const Paragraph = ({ title, children, smallTitle }) => {
  return <Box sx={{ mt: 2, textAlign: "left" }}>

    <Typography sx={{ fontSize: { md: "23px", xs: "16px" } }} variant="body2" ><b>{title}:</b> {children}</Typography>
  </Box>
}

const OverviewBanner = () => {
  return <Grid container sx={{
    width: "100%",
    pt: "80px",
    pb: { xs: 0, md: 20 },
    // minHeight: "100vh",
    backgroundSize: "cover",
    backgroundImage: "linear-gradient(rgba(0, 0, 0, 1.0) 0%, #1a1a1a 99%)",
  }}

    justifyContent={"center"} >

    <Grid item id="overview" xs={12} md={6} justifyContent={"center"} alignItems={"center"} sx={{ pl: { xs: 4, md: 10 }, pr: { xs: 4, md: 10 } }} >
      <Typography variant="h3" sx={{ mb: { xs: 2, md: 5 } }}>
        About the plugin
      </Typography>

      <Typography sx={{ fontSize: { md: "23px", xs: "16px" } }} variant="body2">
        <i>“The Guitar Amp Sim plugin is designed to deliver a raw, powerful and aggressive guitar tone. It offers a thick, brutal sound that resists the often over-polished and dry tone of many digital guitar amp simulators.

          <p>This plugin delivers intensity and "rawness" to recreate the sound of legendary, chunky recordings that always had been rough and violent.</p>

          <p>The idea is that the greatest albums never sounded completely polished, but rather full of energy, power and aggression.</p>

          With this plugin you can bring that same sense of aggression and fullness to your own guitar recordings while maintaining the tightness. ”</i>
      </Typography>
      <Typography sx={{ float: "right", mt: -1, ml: "auto", mr: 0, fontSize: { md: "23px", xs: "16px" } }} variant="body2">
        <i>- Nouri Yetgin </i>
      </Typography>

      <Typography sx={{ mt: { xs: 7, md: 10 }, fontSize: { md: "23px", xs: "16px" } }} variant="body2">
        <b>ToneSuite - Distant</b> was designed in collaboration with guitarist <i>Nouri Yetgin</i> from the band <a href="https://distantofficial.com/" style={{ textDecoration: "underline" }}>Distant</a> during their 2024 European and North American tours. The sound is designed to perfectly fit their intense and energetic live shows, while still being a great tool for the studio and at home. </Typography>
    </Grid >

    <Grid item id="overview" xs={12} md={6} justifyContent={"center"} alignItems={"center"} sx={{ mt: { xs: 5, md: 0 }, pr: { xs: 0, md: 10 } }} >
      <Box sx={{ height: "100%", display: "flex", alignItems: "center", borderRadius: { xs: 0, md: "20px" }, width: "100%", overflow: "hidden" }}>
        <img loading="lazy" src={ecocideBand} style={{ width: "100%", borderRadius: "20px" }} />
      </Box>
    </Grid >
  </Grid >
}

export default OverviewBanner;
