import { Grid, Typography, Box } from "@mui/material";
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

const YoutubeBanner = () => {
  const textColor = "#000"
  return <Box textAlign="center" justifyContent="center" id="cabBanner" container sx={{ width: "100%", pt: 0, pb: 0, background: "white" }}>
    <LiteYouTubeEmbed
      id="6YriVO9f1qQ"
      title="ToneSuite - Distant"
      frameborder="0"
      poster="maxresdefault"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen
      noCookie={true}
      webp={true}
    />
  </Box >
}

export default YoutubeBanner;
