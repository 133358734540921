

import pedalImage from "../../../static/images/distant_amp/postfx_frame.webp"
import { Box, Typography, Grid } from "@mui/material";

const PostFX = () => {
  return <Box sx={{ p: 5, background: "#1a1a1a", justifyContent: "center", alignItems: "center" }} >

    <Grid container justifyContent={"center"} sx={{ background: "#1a1a1a" }}>
      <Grid item xs={12} md={10}>
        <img src={pedalImage} width="100%" />
      </Grid>

    </Grid>
    <Box sx={{ width: "100%", justifyContent: "center" }}>
      <Typography variant="h3" sx={{ textAlign: "center", mb: 3 }}>Post Effects</Typography>
    </Box>
    <Grid container justifyContent={"center"} sx={{ background: "#1a1a1a" }}>
      <Grid item xs={12} md={6} lg={4}>
        <Typography variant="h6" sx={{ textAlign: "left" }}><b>Delay:</b> What is a lead guitar without a bit of delay? This pedal creates a beautiful warm delay sound that reacts like an analog pedal to timing changes.</Typography>
        <br />
        <Typography variant="h6" sx={{ textAlign: "left" }}><b>Reverb:</b> Ambience is key. This reverb can go from small room sounds to big ambient sounds by the turn of a knob. Use the built in filters to control the high and low end and mix in the wet signal to your liking. </Typography>
      </Grid>
    </Grid>

  </Box>
}

export default PostFX;