

import pedalImage from "../../../static/images/distant_amp/Pedal Frame.webp"
import { Box, Typography, Grid } from "@mui/material";

const PrePredals = () => {
  return <Box sx={{ p: 5, background: "#1a1a1a", justifyContent: "center", alignItems: "center" }} >
    <Box sx={{ width: "100%", justifyContent: "center" }}>
      <Typography variant="h3" sx={{ textAlign: "center" }}>Pre Pedals</Typography>
    </Box>
    <Grid container justifyContent={"center"} sx={{ background: "#1a1a1a" }}>
      <Grid item xs={12} md={10}>
        <img src={pedalImage} width="100%" />
      </Grid>

    </Grid>
    <Grid container justifyContent={"center"} sx={{ background: "#1a1a1a" }}>
      <Grid item xs={12} md={6} lg={4}>
        <Typography variant="h6" sx={{ textAlign: "left" }}><b>Shaper:</b> The shaper utilizes a 6-band EQ that helps you shape your signal before it hits any other processor in your chain</Typography>
        <br />
        <Typography variant="h6" sx={{ textAlign: "left" }}><b>Destructor:</b> A secret recipe for destruction. This octaver will truely <i>destroy</i> your sound... but in a good way. </Typography>
        <br />
        <Typography variant="h6" sx={{ textAlign: "left" }}><b>Slaughter:</b> This overdrive does not just scream, <i>it slays</i>. With an added mid control, it allows you to perfectly set the amount of mids you need to push your tone into the amplifier. </Typography>

      </Grid>
    </Grid>

  </Box>
}

export default PrePredals;