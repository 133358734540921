import "./style.scss";

import React, { useEffect, useState } from "react";
import { Typography, Box, Button, CircularProgress, Skeleton } from "@mui/material";

import TopBar from "../../../components/TopBar";

import BottomBar from "../../../components/BottomBar";
import EmailHarvesting from "../../../components/EmailHarvesting";

import ProductDownload from "../../../components/ProductDownload";
import { getProduct } from "../../../util/api/productApi";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "../../../actions/notificationActions";
import { AmplifierBanner, CabinetBanner, StandAloneBanner, MainBanner } from "../../../components/Products/Distant";

import AccountDialog from "../../../components/AccountDialog";
import BuyButton from "../../../components/BuyButton";

import { Helmet } from 'react-helmet';
import OverviewBanner from "../../../components/Products/Distant/Overview";
import YoutubeBanner from "../../../components/Products/Distant/YoutubeBanner";
import { PriceContent } from "../../../components/PriceComponents";
import PrePredals from "../../../components/Products/Distant/PrePedals";
import EQBanner from "../../../components/Products/Distant/EQBanner";
import PostFX from "../../../components/Products/Distant/PostFx";
import PluginUtilities from "../../../components/Products/Distant/Utilities";

import ReactPixel from 'react-facebook-pixel';

const PRODUCT_ID = "distant-amplifier-suite"

const ExtraContentRightTopBar = ({ trigger, onTrial, priceContent, product }) => {
  return <Box
    className={trigger ? "reveal active" : "reveal"}
    sx={{
      p: 2,
      display: "flex",
      flexDirection: "row",
      columnGap: 2,
      color: "#fff",
      alignItems: "center"
    }}>
    {priceContent}
    <Button
      variant="outlined"
      size="small"
      onClick={onTrial}
    >
      Free Trial
    </Button>
    <BuyButton size="small" product={product} sx={{ width: "100px" }} />
  </Box>
}

const ExtraContentMobile = ({ product, priceContent }) => {
  return <Box sx={{
    p: 2,
    display: "flex",
    flexDirection: "row",
    columnGap: 2,
    color: "#fff",
    alignItems: "center"
  }}>
    {priceContent}
    <BuyButton size="small" product={product} sx={{ width: "90px" }} />
  </Box>
}

const DistantAmplifierSuitePage = ({ useHelmet = true }) => {

  const [downloadOpen, setDownloadOpen] = useState(false);
  const [product, setProduct] = useState(undefined)
  const [loading, setLoading] = useState(true)
  const [price, setPrice] = useState(undefined)
  const [trigger, setTrigger] = useState(false);
  const [accountDialogOpen, setAccountDialogOpen] = useState(false);

  window.onscroll = function () {
    var scrollLimit = 500;
    setTrigger(window.scrollY >= scrollLimit);
  };

  const dispatch = useDispatch();
  const state = useSelector((state) => state)

  const fetchProduct = () => {
    getProduct(PRODUCT_ID).then((res) => {
      setLoading(false)
      setProduct(res?.data)

    }).catch(() => {
      setLoading(false)
      dispatch(setError("Something went wrong, please contact support."))
    })
  }

  useEffect(() => {
    fetchProduct()
    // eslint-disable-next-line
  }, [state?.token])

  useEffect(() => {
    if (product) {
      setPrice(`€${(product?.default_price?.unit_amount / 100).toFixed(2)}`)
    }
  }, [product])

  const handleTrial = () => {
    setDownloadOpen(true)
  }

  return (
    <div
      style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
    >
      {useHelmet && <Helmet>
        <title>WaveMind Audio | Tone Suite Distant</title>
        <link rel="canonical" href="https://wavemind.net/product/distant-amplifier-suite" />
      </Helmet>}
      <TopBar
        hideTrigger={trigger}
        rightExtraAction={<ExtraContentRightTopBar trigger={trigger} product={product} onTrial={handleTrial} priceContent={<PriceContent name="ToneSuite - Distant" product={product} hideVAT />} />}
        extraContentMobile={trigger ? <ExtraContentMobile product={product} priceContent={<PriceContent name="ToneSuite - Distant" product={product} hideVAT />} /> : undefined} />
      {/* <CouponAlert /> */}
      <ProductDownload requireEmail open={downloadOpen} onClose={() => setDownloadOpen(false)} productID={PRODUCT_ID} />
      <AccountDialog open={accountDialogOpen} onClose={() => { setAccountDialogOpen(false) }} />

      <MainBanner price={price} product={product} onTrial={handleTrial} />

      <OverviewBanner />
      <PrePredals />
      <AmplifierBanner />
      <CabinetBanner />
      <EQBanner />
      <PostFX />
      <PluginUtilities />
      <YoutubeBanner />
      <StandAloneBanner />
      <EmailHarvesting />
      <BottomBar />
    </div>
  );
};

export default DistantAmplifierSuitePage;
