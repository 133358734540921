import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { Helmet } from 'react-helmet';
import { Box, Typography } from '@mui/material';

const ReclameBanner = () => {
  return (
    <Box sx={{
      bottom: 0,
      width: "100%",
      position: 'fixed',
      alignItems: "center",
      textAlign: "center",
      padding: 2,
      background: "black",
      zIndex: 10,
    }}>
      <Typography variant="h5"><b>HOLIDAY SALE</b> - UP TO 40% OFF</Typography>
    </Box>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <Helmet>
      <title>WaveMind Audio | Specialist in Guitar Software and Plugins</title>
      <link rel="canonical" href="https://wavemind.net/" />
    </Helmet>
    {/* <ReclameBanner /> */}
    <App />

  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
