import { Grid, Typography, Box } from "@mui/material"
import BottomBar from "../../components/BottomBar"
import TopBar from "../../components/TopBar"
import EmailHarvesting from "../../components/EmailHarvesting"
import { Helmet } from "react-helmet"

const Paragraph = ({ title, children, smallTitle }) => {
    return <Box sx={{ mt: 2 }}>
        <Typography variant={smallTitle ? "body1" : "h6"}><b>{title}</b></Typography>
        <Typography sx={{}} variant="body1">{children}</Typography>
    </Box>
}

const RefundPage = () => {
    return <div style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
        <Helmet>
            <title>WaveMind Audio | Refund Policy</title>
            <link rel="canonical" href="https://wavemind.net/refund" />
        </Helmet>
        <TopBar />
        <Grid sx={{ justifyContent: "center", flex: 500, padding: 4, mb: 4, color: "black", background: "white" }} container>
            <Grid item xs={12} md={8} lg={6}>
                <Typography variant="h3">Refund Policy</Typography>
                <Paragraph>
                    Please note that, as a standard practice, WaveMind does not offer refunds for software purchases. We encourage you to carefully evaluate our software and its features before making a purchase decision.
                </Paragraph>

                <Paragraph title="Eligibility:">
                    All software purchases made through WaveMind are final, and no refunds will be issued.
                </Paragraph>
                <Paragraph title="Trial Versions:">
                    We may offer trial versions of our software to allow users to assess its functionality before making a purchase. We encourage users to take advantage of these trial versions to make informed decisions.
                </Paragraph>

                <Paragraph title="Customer Support:">
                    If you encounter any issues or have questions regarding the use of our software, please contact our customer support team at support@wavemind.net We are dedicated to providing assistance and resolving any concerns you may have.
                </Paragraph>

                <Paragraph title="Updates and Improvements:">
                    We are committed to continuous improvement and may release updates to enhance the functionality and performance of our software. These updates are available to registered users free of charge.
                </Paragraph>

                <Paragraph title="License Transfers:">
                    If you wish to transfer your software license to another user, please contact our customer support team for assistance. License transfers may be subject to approval.
                </Paragraph>

                <Paragraph >
                    WaveMind reserves the right to update or modify this refund policy at any time without prior notice. It is the responsibility of the customer to review this policy periodically for changes.
                </Paragraph>
                <Paragraph>
                    Thank you for choosing WaveMind. We appreciate your understanding of our refund policy, and we are here to assist you with any questions or concerns you may have.
                </Paragraph>

            </Grid>
        </Grid>
        <EmailHarvesting />
        <BottomBar />
    </div >
}

export default RefundPage;
