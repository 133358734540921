import "./ampBannerCss.scss"
import { Grid, Typography, Box, Card, CardContent } from "@mui/material";

import fleshweaver from "../../../static/images/distant_amp/Fleshweaver_side.webp"
import servant from "../../../static/images/distant_amp/Servant_side.webp"
import nailwound from "../../../static/images/distant_amp/Nailwound_side.webp"

const TEXT_COLOR = "#000";
const ALT_TEXT_COLOR = "#000";

const Paragraph = ({ title, children, smallTitle }) => {
  return <Box sx={{ mt: 2, textAlign: "left" }}>

    <Typography sx={{ fontSize: { md: "23px", xs: "16px" } }} variant="body2" ><b>{title}:</b> {children}</Typography>
  </Box>
}

const AmplifierBanner = () => {
  return (
    <>
      <Box sx={{ p: 5, background: "radial-gradient(circle, #eee 0%,#aaa 100%)", justifyContent: "center", alignItems: "center", color: TEXT_COLOR }} >
        <Box sx={{ width: "100%", justifyContent: "center" }}>
          <Typography variant="h3" sx={{ textAlign: "center", mb: 3 }}>The Amplifier Collection</Typography>

        </Box>
        <Grid container justifyContent={"center"}>
          <Grid item xs={12} md={6} lg={4} sx={{ color: TEXT_COLOR }}>
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              <b>Tone Suite - Distant</b> contains a collection of 3 custom amplifiers. A sonic pallete that covers all styles from hardcore to deathcore.
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent={"center"} sx={{}}>
          <Grid item xs={12} md={10} sx={{ mt: 3 }}>
            <img src={fleshweaver} width="100%" alt="Fleshweaver" />
          </Grid>

        </Grid>
        <Grid container justifyContent={"center"} sx={{}}>
          <Grid item xs={12} md={6} lg={6} sx={{ color: TEXT_COLOR }}>
            <Typography sx={{ textAlign: "center", mb: 3, color: TEXT_COLOR }} color={TEXT_COLOR} variant="h3">Fleshweaver</Typography>
            <Typography sx={{ textAlign: "center", mb: 3 }} variant="h6">A demon that likes to weave flesh, doesn't that sound brutal? Well, so does this amp. </Typography>
            <Typography sx={{ textAlign: "center", mb: 3 }} variant="h6"> It will hold its own even with the lowest of low tuned guitars. Still not heavy enough? Try the overkill switch to unleash its full potential. You have been warned...</Typography>
          </Grid>
        </Grid>

      </Box >

      <Box sx={{ p: 5, background: "radial-gradient(circle, #FFC63F 0%, #B78E2C 100%)", justifyContent: "center", alignItems: "center", color: TEXT_COLOR }} >
        <Grid container justifyContent={"center"} sx={{}}>
          <Grid item xs={12} md={10}>
            <img src={servant} width="100%" alt="Servant" />
          </Grid>

        </Grid>
        <Grid container justifyContent={"center"} sx={{}}>
          <Grid item xs={12} md={6} lg={6} sx={{}}>
            <Typography sx={{ textAlign: "center", mb: 3, }} variant="h3">Servant</Typography>
            <Typography sx={{ textAlign: "center", mb: 3 }} variant="h6">A trusty old companion in the genre. This time equipped with some extras. Toggle the overkill switch to boost the mids and generate that tightness you're looking for with this amp.</Typography>
            <Typography sx={{ textAlign: "center", mb: 3 }} variant="h6"></Typography>
          </Grid>
        </Grid>

      </Box >

      <Box sx={{ p: 5, background: "radial-gradient(circle, #3a3a3a 0%,#1a1a1a 100%)", justifyContent: "center", alignItems: "center", }} >
        <Grid container justifyContent={"center"} sx={{}}>
          <Grid item xs={12} md={10}>
            <img src={nailwound} width="100%" alt="Nail Wound" />
          </Grid>

        </Grid>
        <Grid container justifyContent={"center"} sx={{}}>
          <Grid item xs={12} md={6} lg={6} sx={{}}>
            <Typography sx={{ textAlign: "center", mb: 3, }} variant="h3">Nail Wound</Typography>
            <Typography sx={{ textAlign: "center", mb: 3 }} variant="h6">This amplifier is not to be underestimated. A true versatile unit, going from sparkling cleans to smooth distorted sounds with ease. Use the overkill knob for some extra rumble and sparkle.</Typography>
            <Typography sx={{ textAlign: "center", mb: 3 }} variant="h6"></Typography>
          </Grid>
        </Grid>

      </Box >
    </>
  )
}

export default AmplifierBanner;
