import { Box, Button, Grid, Typography } from "@mui/material";
import BottomBar from "../../components/BottomBar";
import TopBar from "../../components/TopBar"

import wavemindLogo from "../../static/images/logo512.png"

import ReactPixel from 'react-facebook-pixel';
import { useEffect } from "react";
import { getPricing } from "../../util/api/paymentApi";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setError } from "../../actions/notificationActions";

const PaymentSuccess = () => {
  // 

  const { sessionID } = useParams()
  const dispatch = useDispatch();

  useEffect(() => {

    getPricing(sessionID).then((result) => {
      ReactPixel.track("Purchase", { currency: "EUR", value: result.data })
    }).catch((e) => {
      dispatch(setError(e.response?.data?.detail))
    })
  }, [sessionID])

  return <div
    style={{ minHeight: "100vh", display: "flex", flexDirection: "column", background: "radial-gradient(circle, rgb(204, 204, 214) 0%, rgb(86, 86, 105) 100%)" }}
  >
    <TopBar />
    <Box
      sx={{
        backgroundSize: "cover",
        position: "relative",
        flex: 100,
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        color: "black",
        minHeight: "100vh"
      }}
    >
      <Grid container justifyContent={"center"} sx={{ mt: "-150px", }}>
        <Grid xs={12} md={8}>
          <img alt="WaveMind" src={wavemindLogo} style={{ maxWidth: "128px" }} />
          <Typography
            variant="h2"
            fontSize={30}
            sx={{ fontSize: 30, }}
          >
            Enjoy your new amp sim!
          </Typography>
          <Typography
            variant="h4"
            fontSize={18}
            sx={{ fontSize: 18, }}
          >
            You will receive a license key in you mail shortly.
          </Typography>

          <Box sx={{ mt: 3 }}>
            <Button sx={{ background: "white" }} href="/account#licenses" variant="contained">Go to Licenses</Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
    <BottomBar />
  </div>
}

export default PaymentSuccess;
